<template>
  <div :class="className">
    <div class="text">
      <div v-if="showText">{{ text }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'count-down',
    props: {
      className: {
        type: String,
        default: 'select-address'
      },
      time: {
        type: Number,
        default: 0 //时间戳
        // observer(newVal, oldVal, changedPath) {
        //   console.log(newVal)
        //   this.outTimeStart(newVal)
        // }
      },
      showText: {
        type: Boolean,
        default: true //时间戳
      },
      format: {
        type: String,
        default: 'hh小时mm分ss秒' //时间格式 dd天hh小时mm分ss秒
      },
      space: {
        type: Number,
        default: 1000
      },
      statusIcon: {
        type: Boolean,
        default: true
      },
      showIcon: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        componentsName: '倒计时',
        outTimeTarget: 0,
        text: ''
        // path_images: config.path_images
      }
    },
    mounted() {
      // console.log(this.time, 'timetimetimetimetimetimetimetimetimetimetimetimetime')

      this.outTimeStart()
    },
    methods: {
      /**
       * 在组件实例被从页面节点树移除时执行*/
      _detached(origin) {
        console.log(`${this.componentsName} 移出节点 ${origin ? origin + '.' : ''}detached()`)
        this.endCountDown()
      },
      outTimeStart() {
        let data = this.countDown()
        this.outTimeCallback(data)
        if (!data.end)
          this.outTimeTarget = setInterval(() => {
            console.log("1")
            let data = this.countDown()
            this.outTimeCallback(data)
          }, this.space)
      },
      outTimeCallback(res) {
        if (this.showText) {
          this.text = res.text
        } else {
          this.$emit('change', res)
        }
        if (res.end) this.outTimeEnd(res)
      },
      outTimeEnd(res) {
        console.log('倒计时结束')
        this.endCountDown()
        this.$emit('end', res)
      },
      endCountDown() {
        clearInterval(this.outTimeTarget)
      },
      countDown(time = this.time, style = this.format) {
        // console.log(time, style, 'outTimeStart-outTimeStartoutTimeStartoutTimeStart');
        let nowTime = new Date().getTime()
        let targetTime = new Date(time).getTime()
        let minusTime = targetTime - nowTime
        minusTime = Math.max(0, minusTime)
        minusTime = parseInt(minusTime / 1000)
        //小时、分、秒需要取模运算
        let o = {
          // "d": parseInt(leftTime/(60*60*24)), //hour
          // "h": parseInt(leftTime/(60*60)/24), //hour
          h: parseInt(minusTime / (60 * 60)), //hour
          m: parseInt((minusTime / 60) % 60), //minute
          s: parseInt(minusTime % 60), //second
          S: parseInt(((targetTime - nowTime) / 100) % 10) //millisecond
        }
        if (/d+/gi.test(style)) {
          o.d = parseInt(minusTime / (60 * 60 * 24))
          o.h = parseInt((minusTime / (60 * 60)) % 24)
        }
        this.amendNegative(o)
        let data = {
          minusTime: minusTime,
          end: minusTime <= 0,
          data: o,
          text: ''
        }
        if (typeof style == 'string' && !!style) {
          let keys = Object.keys(o)
          let d = {}
          keys.forEach(key => (d[`${key}+`] = o[key]))
          for (let k in d) {
            if (new RegExp('(' + k + ')').test(style)) {
              let str = ''
              let originStr = d[k]
              if (RegExp.$1.length > 1 && originStr.toString().length < 2) {
                str = ('00' + originStr).substr(originStr.toString().length)
              } else {
                str = d[k]
              }
              style = style.replace(RegExp.$1, str)
            }
          }
          data.text = style
        }
        return data
      },
      amendNegative(data = {}) {
        let keys = Object.keys(data)
        keys.forEach(key => {
          if (data[key] < 0) data[key] = 0
        })
        return data
      }
    },
    beforeDestroy() {
       this.endCountDown()
    },
    // clearInterval(window.outTimeTarget)
    watch: {
      time(newVal) {
        console.log(newVal, 'timetimetimetimetimetimetimetimetimetimetimetimetime')

        this.outTimeStart(parseInt(newVal) + 1000 * 60 * 30)
      }
    }
  }
</script>

<style scoped>
  .order_time {
    width: 10px;
    height: 10px;
    margin-right: 2px;
    vertical-align: middle;
  }
  .className{
	  flex-direction: row;
	  align-items: center;
  }

  .text {
    vertical-align: middle;
    font-size: 12px;
  }
</style>
