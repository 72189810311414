<!--
 * @Descripttion: (售后订单)
-->
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择关键字类型"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.status"
                       placeholder="售后状态"
                       style="width: 120px">
              <el-option
                v-for="item in refundStatusArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.type"
                       placeholder="售后类型"
                       style="width: 120px">
              <el-option
                v-for="item in refundTypeArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.recordType"
                       placeholder="处理状态"
                       style="width: 120px">
              <el-option
                v-for="item in recordTypeArray"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="查询申请时间">
            <el-date-picker
              v-model="searchData.selectTime"
              type="daterange"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button @click="downloadExcel" icon="el-icon-download">导出</el-button>
          </el-form-item>
        </el-form>
        <div class="order-page-title">
          <div class="item_width">售后单号</div>
          <div class="item_width2">售后商品</div>
          <div class="item_width">商品类型</div>
          <div class="item_width">退款类型</div>
          <div class="item_width2">退款原因</div>
          <div class="item_width">退款金额</div>
          <div class="item_width">售后状态</div>
          <div class="item_width">操作</div>
        </div>
      </template>
      <template v-slot:table="row">
        <div class="order-page-list" v-if="tableData.length > 0">
          <div class="order-item divide-list" v-for="(refund, index) in tableData" :key="index">
            <div class="order-item-content">
              <div class="center-div item_width">
                <div>{{refund.id}}</div>
              </div>
              <div class="item_width2 center-div">
                <div v-if="refund.orderGoodsListJson">
                  <div class="order-item-goods flex" v-for="(item, index) of refund.orderGoodsListJson" :key="index">
                    <el-image class="radius-6" style="width: 62px; height: 62px" :src="item.cover"></el-image>
                    <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                      <div class="goods-name flex-1">{{item.goodsName}}</div>
                      <div class="gray">{{item.skuName}}</div>
                    </div>
                  </div>
                </div>
                <!-- <div class="order-item-goods flex" v-if="refund.orderGoodsJson">
                  <el-image class="radius-6" style="width: 62px; height: 62px" :src="refund.orderGoodsJson.cover"></el-image>
                  <div class="flex-1 padding-left-10 padding-right-30 flex flex-column">
                    <div class="goods-name flex-1">{{refund.orderGoodsJson.goodsName}}</div>
                    <div class="gray">{{refund.orderGoodsJson.skuName}}</div>
                  </div>
                </div> -->
              </div>
              <div class="flex center-div item_width">
                <div>{{refund.businessType == 1 ? '普通商品' : '批发商品'}}</div>
              </div>
              <div class="item_width center-div">
                <div>{{refundType[refund.type]}}</div>
              </div>
              <div class="item_width2 center-div">
                <div>{{refund.remark}}</div>
              </div>
              <div class="item_width center-div">
                <div class="f18 b">￥{{refund.realRefundAmount}}</div>
              </div>
              <div class="center-div item_width">
                <div>
                  <CountDown v-if="refund.status == 3" style="color: red;margin-bottom:10px" :time="parseInt(refund.userSendTime) + 1000 * 60 * 60 * 24 * 10" format="还剩dd天hh小时mm分自动确认收货" @end="endTheTimer()" />
                  <div :class="{red:[1,2,3,4,5].indexOf(refund.status)>-1}"> {{refundStatus[refund.status]}}</div>
                </div>
              </div>
              <div class="item_width center-div">

                <el-button class="function-button" size="medium" type="text"  @click="showRefundDetail(refund.id)">详情</el-button>

                <el-popover v-if="refund.status === 1" trigger="click" v-model="refund.showY" placement="left" width="320">
                  <div class="text-center padding-10">
                    <div class="f16 b">{{refund.type === 1 ? '同意退款？' : refund.type === 2 ? '同意退货？' : '同意换货？'}}</div>
                  </div>
                  <div class="text-left padding-10" v-if="refund.type === 1">{{`确认退款后，退款金额￥${refund.realRefundAmount}将直接退入买家微信支付账户内`}}</div>
                  <div class="padding-10" v-else>
                    <div class="" v-if="refundAddress.length === 0 || !refund.useDefaultRefundAddress || isEmpty(defaultRefundAddress)">
                      <div class="divide-list flex cursor-pointer" v-for="(address,index) in refundAddress" @click="refund.refundAddressIndex = index" :key="index">
                        <div class="flex-1">
                          <el-tag size="mini" v-if="address.isDefault === 1">默认</el-tag>
                          {{`${address.addressInfo} ，${address.receiveName}，${address.receivePhone}`}}
                        </div>
                        <div class="margin-left" style="width: 50px;font-size: 24px">
                          <i v-if="index === refund.refundAddressIndex" class="el-icon-check"></i>
                        </div>
                      </div>
                    </div>
                    <div class="" v-else>
                      {{`默认退回地址：${defaultRefundAddress.addressInfo} ，${defaultRefundAddress.receiveName}，${defaultRefundAddress.receivePhone}`}}
                    </div>
                    <div class="" v-if="refundAddress.length === 0 || !refund.useDefaultRefundAddress || isEmpty(defaultRefundAddress)">
                      <el-link href="/refundAddressList" type="primary" :underline="false" target="_blank"><i class="el-icon-plus"></i>添加新地址</el-link>
                    </div>
                    <el-button type="text" v-else @click="refund.useDefaultRefundAddress = false">切换地址</el-button>
                  </div>
                  <div class="text-center padding-10">
                    <el-button type="info" size="mini" @click="refund.showY = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="reviewSuccess(refund)">{{refund.type === 1 ? '同意退款' : refund.type === 2 ? '同意退货' : '同意换货'}}</el-button>
                  </div>
                  <el-button class="function-button" slot="reference" size="medium" type="text">同意</el-button>
                </el-popover>

                <el-popover v-if="refund.status === 1" trigger="click" v-model="refund.showN" placement="left" width="320">
                  <div class="text-center padding-10">
                    <div class="f16 b">拒绝的原因</div>
                  </div>
                  <div class="padding-10">
                    <el-input type="textarea" :rows="5" resize="none" placeholder="必须输入拒绝的原因才可以拒绝" v-model="refund.refuseRemark" maxlength="30" show-word-limit></el-input>
                  </div>
                  <div class="text-center padding-10">
                    <el-button type="info" size="mini" @click="refund.showN = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="reviewRefuse(refund)" :disabled="!refund.refuseRemark">确定</el-button>
                  </div>
                  <el-button class="function-button" slot="reference" size="medium" type="text">拒绝</el-button>
                </el-popover>

                <el-popover v-if="refund.status === 3" trigger="click" v-model="refund.showY" placement="left" width="320">
                  <div class="text-center padding-10">
                    <div class="f16 b">{{refund.type === 2 ? '确认收货并退款？' : '确认收货？'}}</div>
                  </div>
                  <div class="text-left padding-10" v-if="refund.type === 2">{{`确认收到货物，并允许退款￥${refund.realRefundAmount}`}}</div>
                  <div class="text-left padding-10" v-if="refund.type === 3">{{'收到货物后，请尽快为客户换货发回去'}}</div>
                  <div class="text-center padding-10">
                    <el-button type="info" size="mini" @click="refund.showY = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="confirmReceive(refund)">确定</el-button>
                  </div>
                  <el-button class="function-button" slot="reference" size="medium" type="text">确认收货</el-button>
                </el-popover>

                <el-popover v-if="refund.status === 3" trigger="click" v-model="refund.showN" placement="left" width="320">
                  <div class="text-center padding-10">
                    <div class="f16 b">没收到货?</div>
                  </div>
                  <div class="text-left padding-10">{{'确定没收到客户寄回的货物，拒绝这次售后?'}}</div>
                  <div class="text-center padding-10">
                    <el-button type="info" size="mini" @click="refund.showN = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="confirmRefuse(refund)">确定</el-button>
                  </div>
                  <el-button class="function-button" slot="reference" size="medium" type="text">未收到货</el-button>
                </el-popover>

                <el-popover v-if="refund.status === 4 && refund.type === 3" trigger="click" v-model="refund.showS" placement="left" width="320">
                  <div class="text-center padding-10">
                    <div class="f16 b">选择发货快递</div>
                  </div>
                  <div class="padding-10 text-center">
                    <el-select v-model="refund.expressCode" placeholder="请选择快递公司" style="width:250px;">
                      <el-option v-for="item in expressCompanyArr" :key="item.code" :label="item.name" :value="item.code"> </el-option>
                    </el-select>
                    <el-input class="margin-top-10" v-model="refund.expressSn" placeholder="请选输入快递单号" style="width:250px;"></el-input>
                  </div>
                  <div class="text-center padding-10">
                    <el-button type="info" size="mini" @click="refund.showS = false">取消</el-button>
                    <el-button type="primary" size="mini" @click="sendOrder(refund)">确定</el-button>
                  </div>
                  <el-button class="function-button" slot="reference" size="medium" type="text">发货</el-button>
                </el-popover>

              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div style="text-align: center; margin-top: 200px;" class="no-data">暂无数据</div>
        </div>
      </template>
      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5,10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>
  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {
    recordTypeArray,
    refundStatus,
    refundStatusArray,
    refundType,
    refundTypeArray
  } from '../../../assets/js/status.js'
  import {
    disposeSearchData,
    getNextDayTimestamp,
    isEmpty,
    isNormalLongId,
    parseTimeArray
  } from '../../../assets/js/utils'
  import {plusDownloadExcelTask} from '../../../store/excel'
  import CountDown from '../../../components/countDown.vue'

  export default {
    name: 'supplierRefundOrderList',
    components:{
      CountDown
    },
    data(){
      return {
        loading: false,
        /* 数据表格 */
        refundType,refundTypeArray,
        refundStatus,refundStatusArray,
        recordTypeArray,
        searchKeyType:[
          {id: 'orderId', name: '订单编号'},
          {id: 'id', name: '退款编号'},
          {id: 'receiveName', name: '收件人姓名'},
          {id: 'receivePhone', name: '收件人电话'},
          {id: 'receiveAddressInfo', name: '收件人地址'},
          {id: 'phone', name: '会员手机号'},

        ],
        searchData:{
          searchType:'id',
          searchValue:'',
          status:0,
          type:0,
          recordType:0,
          selectTime:[],
        },
        expressCompanyArr:[],
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        refundAddress:[],//售后退回地址
        totalListNum: 0, // 列表总条量
        pageSize: 5, // 每页显示得条数
      }
    },
    computed:{
      defaultRefundAddress(){
        let list =  this.refundAddress.filter(res=>res.isDefault === 1);
        if(list.length){return list[0]}
        return {};
      }
    },
    created(){
      this.parseQuery();
      this.getList();
      this.getExpressCompany();
      this.getRefundAddress();
    },
    methods:{
      isEmpty,
      isNormalLongId,
      parseQuery(){
        let params = this.$route.query;
        if(params['recordType'] === '1'){
          this.searchData.recordType = 1
        }
      },
    //倒计时结束
    endTheTimer() {
      this.$router.go(0);
    },
      /**
       * 拼接搜索参数
       */
      jointSearchData(){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
          businessType:1
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data);
        let selectTime = data.selectTime;
        let startTime = 0;
        let endTime = 0;

        if(!isEmpty(selectTime)){
          let times = selectTime.map(res=>new Date(res).getTime())
          startTime = times[0];
          endTime = getNextDayTimestamp(times[1]);
        }

        data.startTime = startTime;
        data.endTime = endTime;

        delete  data.selectTime;
        return data;
      },
      /**
       * 搜索
       */
      search(){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierOrder.refundOrderList,{params:data}).then(res=>{
            parseTimeArray(res.data.records,["createTime"]);
            res.data.records.forEach(itemRes=>{
              itemRes.showY = false;
              itemRes.showN = false;
              itemRes.refuseRemark = '';
              itemRes.useDefaultRefundAddress = true;
              itemRes.refundAddressIndex = 0;
              itemRes.showS = false;
              itemRes.expressCode = '';
              itemRes.expressSn = '';
              if(!isEmpty(itemRes.orderGoodsListJson)){
                let orderGoodsListObj

                try {
                  orderGoodsListObj = JSON.parse(itemRes.orderGoodsListJson);
                }catch (e) {
                  orderGoodsListObj = {}
                }
                itemRes.orderGoodsListJson = orderGoodsListObj;
              }

              if(!isEmpty(itemRes.orderGoodsJson)){
                let orderGoodsObj
                try {
                  orderGoodsObj = JSON.parse(itemRes.orderGoodsJson);
                }catch (e) {
                  orderGoodsObj = {}
                }
                itemRes.orderGoodsJson = orderGoodsObj;
              }

              if(!isEmpty(itemRes.orderInfoJson)){
                let orderObj
                try {
                  orderObj = JSON.parse(itemRes.orderInfoJson)
                }catch (e) {
                  orderObj = {}
                }
                itemRes.orderInfoJson = orderObj

              }
              let refundGoodsList = [];
              if(!isEmpty(itemRes.orderGoodsJson)){
                refundGoodsList = refundGoodsList.concat(itemRes.orderGoodsJson);
              }
              console.info(refundGoodsList);
              itemRes.refundOrderList = refundGoodsList;
            });
            console.info(res.data.records);
            this.totalListNum = parseInt(res.data.total);
            this.tableData = res.data.records;

            resolve(res);
          }).catch(()=>{
            this.tableData = [];
          }).finally(()=>{
            this.loading = false;
          })
        })
      },
      getExpressCompany(){
        let name = "";
        return new Promise((resolve, reject) => {
          this.axios.get(URL.expressCompany.list,{name:name}).then(res=>{
            this.expressCompanyArr = res.data;
            resolve(res);
          }).catch(()=>{
            this.tableData = [];
          }).finally(res=>{
          })
        })
      },
      getRefundAddress(){
        return new Promise((resolve, reject) => {
          this.axios.get(URL.supplier.refundAddress,{params:{type:4}}).then(res=>{
            if (res.code !== 0) {
              this.$message.error(res.msg);
            } else {
              this.refundAddress = res.data || [];
            }
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
          })
        })
      },
      showRefundDetail(refundId){
          this.$router.push({
            path:'/orderRefundDetail',
            query:{
              refundId:refundId,
            }
          });
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val){
        this.currentPage = val;
        this.getList();
      },
      reviewSuccess(refund){
        refund.showY = false;
        let selectedRefundAddress = {};

        if(refund.type === 2 || refund.type === 3){
          if(this.refundAddress.length === 0){
            this.$message.error("请先去添加退货地址");
            return false;
          }
          // if(refund.useDefaultRefundAddress){
          //   selectedRefundAddress = this.defaultRefundAddress;
          // }else {
          //   selectedRefundAddress = this.refundAddress[refund.refundAddressIndex];
          // }

        selectedRefundAddress = this.refundAddress[refund.refundAddressIndex]

          if(isEmpty(selectedRefundAddress)){
            this.$message.error("请选择售后寄回地址");
            return false;
          }
        }
        let data = {
          refundId:refund.id,
          realRefundAmount: refund.realRefundAmount,
          addressId: selectedRefundAddress.id,
        };
        this.loading = true;

       this.axios.post(URL.supplierOrder.reviewRefundSuccess, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
       }).catch(()=>{

       }).finally(()=>{
       });
      },
      reviewRefuse(refund) {
        refund.showN = false;
        let data = {
          refundId:refund.id,
          remark:refund.refuseRemark,
        };
        this.axios.post(URL.supplierOrder.reviewRefundRefuse, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        });
      },
      confirmReceive(refund){
        refund.showY = false;
        this.loading = true
        let data = {
          refundId:refund.id
        };
        this.axios.post(URL.supplierOrder.refundConfirmRefund, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        });
      },
      confirmRefuse(refund){
        refund.showN = false;
        this.loading = true
        let data = {
          refundId:refund.id
        };
        this.axios.post(URL.supplierOrder.operatingNotReceivedOrder, this.qs.stringify(data)).then(res=>{
          if(res.code === 0){
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        });
      },
      sendOrder(refund){
        refund.showS = false;
        let data = {
          refundId:refund.id,
          expressCode:refund.expressCode,
          expressSn:refund.expressSn,
        };
        this.loading = true;
        this.axios.post(URL.supplierOrder.refundOrderReSend,this.qs.stringify(data)).then(res=>{
          if(res.code !== 0){
            this.$message({
              type: 'error',
              message: res.msg
            });
          }else{
            this.getList()
          }
        }).catch(res=>{
        }).finally(()=>{
          this.loading = false;
        });
      },
      /**
       * 导出excel
       */
      downloadExcel(){
        let searchData = this.jointSearchData();
        let data = {
          name:'供应商售后订单报表',
          params:{
            excelType:1002,
            excelParameterJson:JSON.stringify(searchData),
          },
        };
        plusDownloadExcelTask.call(this,data);
      },
    },
    watch: {
    }
  }
</script>

<style lang="less">
.item_width{
  width: 170px;
  word-break: break-all;
}
.item_width2{
  width: 260px;
  word-break: break-all;
}
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
  .form-item .el-input__inner{
      max-width: 350px;
  }
</style>
<style lang="less" scoped>
  @import "../../../assets/css/order";
</style>
